import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions, {
  checkRentalProgram,
} from '../Redux/ApplicationRedux';

// Styles
import { Fonts } from '../Themes';
import styles from './Styles/ScreenStyles';

class ConsentToShareDataForm extends Component {
  render() {
    const { locale, application } = this.props;

    const isRentalProgram = checkRentalProgram(application);
    console.log(
      'ConsentToShareDataForm -> render -> isRentalProgram',
      isRentalProgram,
    );

    return (
      <View>
        <Row>
          <Col sm={12}>
            <Text
              style={[
                styles.block,
                { textAlign: 'right', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.addressName1')}
            </Text>
            <Text
              style={[
                styles.block,
                { textAlign: 'right', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.addressName2')}
            </Text>
            <Text style={[styles.block, { textAlign: 'right' }]}>
              {I18n.t('ConsentToShareData.address1')}
            </Text>
            <Text style={[styles.block, { textAlign: 'right' }]}>
              {I18n.t('ConsentToShareData.address2')}
            </Text>

            {/* {isRentalProgram && (
              <View>
                <Text
                  style={[
                    Fonts.style.sectionHeader,
                    styles.bold,
                    styles.underline,
                    { fontWeight: Fonts.weight.bold },
                  ]}
                >
                  {I18n.t('ConsentToShareData.title')}
                </Text>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet1')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet2')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet3')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet4')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet5')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet6')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet7')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet8')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet9')}
                  </Text>
                </View>
              </View>
            )} */}
            <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                { fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.title2')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section1')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section2')}
            </Text>

            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet10')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet11')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet12')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet13')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet14')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet15')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet15a')}
              </Text>
            </View>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section3')}
            </Text>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section4')}
            </Text>

            <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                styles.center,
                { textAlign: 'left', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.title4')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section5')}
            </Text>

            <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                styles.center,
                { textAlign: 'left', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.title5')}
            </Text>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section6')}
            </Text>

            <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                styles.center,
                { textAlign: 'left', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.title6')}
            </Text>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section7')}
            </Text>

            <Text
              style={[
                styles.block,
                { textAlign: 'left', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.addressName1')}
            </Text>
            <Text
              style={[
                styles.block,
                { textAlign: 'left', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.addressName2')}
            </Text>
            <Text style={[styles.block, { textAlign: 'left' }]}>
              {I18n.t('ConsentToShareData.address1')}
            </Text>
            <Text style={[styles.block, { textAlign: 'left' }]}>
              {I18n.t('ConsentToShareData.address2')}
            </Text>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.signBelow')}
            </Text>
          </Col>
        </Row>
      </View>
    );
  }
}

const mapStateToProps = ({
  i18n: { locale },
  options: { options },
  app: { initilized },
  persist: { application },
}) => ({
  locale,
  options,
  initilized,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  saveApplication: (application) =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsentToShareDataForm);
