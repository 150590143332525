import { Platform } from 'react-native';
import moment from 'moment';
import _ from 'lodash';
import { Fonts } from '../Themes';

import { NO_OPTION } from '../Config/ApplicationConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const R = require('ramda');

// src: https://html-online.com/articles/get-url-parameters-javascript/
// Usage:
// var number = getUrlVars()["x"];
// var mytext = getUrlVars()["text"];
export const getUrlVars = () => {
  const vars = {};
  const parts = window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    },
  );
  return vars;
};

// Src: https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key
// Usage:
// const a = { b: { c: 3 } }
// const three = objectByString(a, 'b.c');
export const objectByString = (o, s) => {
  return _.get(o, s);
  // s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  // s = s.replace(/^\./, ''); // strip a leading dot
  // const a = s.split('.');
  // for (let i = 0, n = a.length; i < n; ++i) {
  //   const k = a[i];
  //   if (k in o) {
  //     o = o[k];
  //   } else {
  //     return;
  //   }
  // }
  // return o;
};

export const isRequired = (value) => (value ? undefined : 'error.required');
export const isRequiredRule = {
  required: { value: true, message: 'error.required' },
};

// export const maxLength = max => value =>
//   isRequired(value) || value.length > max ? `Must be ${max} characters or less` : undefined

// export const maxLength15 = maxLength(15)

// export const minLength = min => value =>
//   isRequired(value) || value.length < min ? `Must be ${min} characters or more` : undefined

// export const minLength2 = minLength(2)

export const isInteger = (value) =>
  isRequired(value) || !/^\d+$/i.test(value) ? 'error.isNumber' : undefined;

export const isIntegerRule = {
  pattern: {
    value: /^\d+$/,
    message: 'error.isNumber',
  },
};

export const isNumber = (value) =>
  isRequired(value) || isNaN(Number(value)) ? 'error.isNumber' : undefined;
export const isNumberRule = {
  pattern: {
    value: /^[+-]?([0-9]*[.])?[0-9]+$/,
    message: 'error.isNumber',
  },
};

// export const minValue = min => value =>
//   isRequired(value) || value < min ? `Must be at least ${min}` : undefined

// export const minValue18 = minValue(18)

export const isDay = (value) =>
  isRequired(value) || isInteger(value) || value > 31 || value < 1
    ? 'error.invalidDay'
    : undefined;
export const isDayRule = {
  pattern: {
    value: /^\d+$/,
    message: 'error.isNumber',
  },
  min: {
    value: 1,
    message: 'error.invalidDay',
  },
  max: {
    value: 31,
    message: 'error.invalidDay',
  },
};

export const isMonth = (value) =>
  isRequired(value) || isInteger(value) || value > 12 || value < 1
    ? 'error.invalidMonth'
    : undefined;
export const isMonthRule = {
  pattern: {
    value: /^\d+$/,
    message: 'error.isNumber',
  },
  min: {
    value: 1,
    message: 'error.invalidMonth',
  },
  max: {
    value: 12,
    message: 'error.invalidMonth',
  },
};

export const isYear = (value) =>
  isRequired(value) || isInteger(value) || value < 1753 || value > 9999
    ? 'error.invalidYear'
    : undefined;
export const isYearRule = {
  pattern: {
    value: /^\d+$/,
    message: 'error.isNumber',
  },
  min: {
    value: 1753,
    message: 'error.invalidYear',
  },
  max: {
    value: 9999,
    message: 'error.invalidYear',
  },
};

export const isPastYear = (value) => {
  const thisYear = moment().year();
  const isInteger = /^\d+$/i.test(value);

  // console.log('isPastYear', isInteger, thisYear);
  return (value && !isInteger) || (value && (value < 1753 || value > thisYear))
    ? 'error.invalidPastYear'
    : undefined;
};
export const isPastYearRule = {
  pattern: {
    value: /^\d+$/,
    message: 'error.isNumber',
  },
  min: {
    value: 1753,
    message: 'error.invalidYear',
  },
  max: {
    value: moment().year(),
    message: 'error.invalidYear',
  },
};

export const isRequiredPastYear = (value) => {
  const thisYear = moment().year();
  const isInteger = /^\d+$/i.test(value);

  // console.log('isPastYear', isInteger, thisYear);
  return isRequired(value) ||
    (value && !isInteger) ||
    (value && (value < 1753 || value > thisYear))
    ? 'error.invalidPastYear'
    : undefined;
};

export const isEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'error.invalidEmail'
    : undefined;
export const isEmailRule = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'error.invalidEmail',
  },
};

export const isRequiredEmail = (value) =>
  isRequired(value) || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'error.invalidEmail'
    : undefined;

export const isAlphaNumeric = (value) =>
  isRequired(value) || /[^a-zA-Z0-9 ]/i.test(value)
    ? 'error.alphanumeric'
    : undefined;

export const isPhoneNumber = (value) =>
  value && value.replace(/\D/g, '').length !== 10
    ? 'error.invalidPhone'
    : undefined;
export const isPhoneNumberRule = {
  pattern: {
    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i,
    message: 'error.invalidPhone',
  },
};
export const isRequiredPhoneNumber = (value) =>
  isRequired(value) || value.replace(/\D/g, '').length !== 10
    ? 'error.invalidPhone'
    : undefined;

export const isSsnNumber = (value) =>
  isRequired(value) || value.replace(/\D/g, '').length !== 9
    ? 'error.invalidSSN'
    : undefined;

export const isEinNumber = (value) =>
  isRequired(value) || value.replace(/\D/g, '').length !== 9
    ? 'error.invalidEIN'
    : undefined;

export const isZipcode = (value) =>
  isRequired(value) ||
  (value.replace(/\D/g, '').length !== 5 &&
    value.replace(/\D/g, '').length !== 9)
    ? 'error.invalidZip'
    : undefined;

export const isOptionalZipcode = (value) =>
  value &&
  value.replace(/\D/g, '').length !== 5 &&
  value.replace(/\D/g, '').length !== 9
    ? 'error.invalidZip'
    : undefined;
export const isZipcodeRule = {
  pattern: {
    value: /^\d{5}(?:[-\s]\d{4})?$/,
    message: 'error.invalidZip',
  },
  minLength: {
    value: 5,
    message: 'error.invalidZip',
  },
  maxLength: {
    value: 10,
    message: 'error.invalidZip',
  },
};
export const zipcodeFormat = (input) => {
  if (input) {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Based upon the length of the string, we add formatting as necessary
    const size = input.length;
    if (size < 6) {
      input = input;
    } else {
      input = `${input.substring(0, 5)}-${input.substring(5)}`;
    }
    return input;
  }
  return '';
};

export const isPinRule = {
  pattern: {
    value: /^\d{6}?$/,
    message: 'error.invalidPin',
  },
  minLength: {
    value: 6,
    message: 'error.invalidPin',
  },
  maxLength: {
    value: 6,
    message: 'error.invalidPin',
  },
};
export const pinFormat = (input) => {
  if (input) {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Limit to 6 numbers
    const size = input.length;
    if (size > 6) {
      input = input.substring(0, 6);
    }
    return input;
  }
  return '';
};

export const phoneFormat = (input) => {
  if (input) {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Based upon the length of the string, we add formatting as necessary
    const size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = input.substring(0, 3);
    } else if (size < 7) {
      input = `${input.substring(0, 3)}-${input.substring(3, 6)}`;
    } else {
      input = `${input.substring(0, 3)}-${input.substring(
        3,
        6,
      )}-${input.substring(6)}`;
    }
    return input;
  }
  return '';
};

export const dateFormat = (input) => {
  if (input) {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Based upon the length of the string, we add formatting as necessary
    const size = input.length;
    if (size === 0) {
      input = input;
    } else if (size < 3) {
      input = input.substring(0, 2);
    } else if (size < 5) {
      input = `${input.substring(0, 2)}/${input.substring(2, 4)}`;
    } else {
      input = `${input.substring(0, 2)}/${input.substring(
        2,
        4,
      )}/${input.substring(4)}`;
    }
    return input;
  }
  return '';
};

export const ssnFormat = (input) => {
  if (input) {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Based upon the length of the string, we add formatting as necessary
    const size = input.length;
    if (size === 0) {
      input = input;
    } else if (size < 4) {
      input = input.substring(0, 3);
    } else if (size < 6) {
      input = `${input.substring(0, 3)}-${input.substring(3, 5)}`;
    } else {
      input = `${input.substring(0, 3)}-${input.substring(
        3,
        5,
      )}-${input.substring(5)}`;
    }
    return input;
  }
  return '';
};
export const einFormat = (input) => {
  if (input) {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Based upon the length of the string, we add formatting as necessary
    const size = input.length;
    if (size === 0) {
      input = input;
    } else if (size < 3) {
      input = input.substring(0, 2);
    } else {
      input = `${input.substring(0, 2)}-${input.substring(2)}`;
    }
    return input;
  }
  return '';
};

export const floatFormat = (input) => {
  if (input) {
    // Strip all characters from the input except digits
    return input.replace(/[^\d.]/g, '');
  }
  return '';
};

// Source: https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-dollars-currency-string-in-javascript
export function formatCurrency(n, c, d, t) {
  var c = isNaN((c = Math.abs(c))) ? 2 : c;
  var d = d == undefined ? '.' : d;
  var t = t == undefined ? ',' : t;
  const s = n < 0 ? '-' : '';
  const i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
  var j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function getBaseString(base, index) {
  return index >= 0 ? `${base}[${index}]` : base;
}
export function getBase(application, base, index) {
  if (!application[base]) return {};
  return index >= 0 ? application[base][index] : application[base];
}

export function getPersonId(application, base, index) {
  // console.log(
  //   'getPersonId index',
  //   base,
  //   index,
  //   parseInt(index, 10),
  //   parseInt(index, 10) >= 0
  // );

  // If Index is an integer and is greater or equal to 0
  let personId;
  if (parseInt(index, 10) >= 0) {
    personId = R.pathOr(`${base}_${index}`, [base, index, 'id'], application);
    if (personId === -1 || personId === '-1') {
      return `${base}_${index}`;
    }
  } else {
    // console.log('no index', R.path([base, 'id'], application));
    personId = R.pathOr(base, [base, 'id'], application);
    if (personId === -1 || personId === '-1') {
      return base;
    }
  }
  return personId;

  // return index >= 0
  //   ? R.pathOr(0, [base, index, 'id'], application) > 0
  //     ? R.path([base, index, 'id'], application)
  //     : `${base}_${index}`
  //   : R.pathOr(0, [base, 'id'], application) > 0
  //   ? R.path([base, 'id'], application)
  //   : base;
}

export function flattenStyle(style) {
  let styles = null;
  if (style && typeof style.forEach === 'function') {
    style.forEach((ele) => {
      if (styles === null) styles = {};
      if (ele && ele.fontWeight && ele.fontWeight === Fonts.weight.bold) {
        styles = { ...styles, ...ele, fontFamily: 'Lato-Bold' };
      } else {
        styles = { ...styles, ...ele };
      }
    });
  } else {
    styles = style;
  }
  return styles;
}

export const hasDocumentAndKeyword = (
  documents,
  personId,
  documentType,
  keywordValue,
) => {
  if (documents && typeof documents.forEach === 'function') {
    documents.forEach((ele) => {
      if (
        ele &&
        ele.documentType === documentType &&
        ele.personId === personId &&
        ele.keywordValue === keywordValue
      ) {
        return true;
      }
    });
  }
  return false;
};
// Web doesn't require signatures
export const hasSignatureDocument = (documents, personId, documentType) => {
  if (Platform.OS === 'web') {
    return true;
  }
  return hasDocument(documents, personId, documentType);
};
export const hasDocument = (documents, personId, documentType) => {
  let found = false;
  if (documents && typeof documents.forEach === 'function') {
    documents.forEach((ele) => {
      if (
        ele &&
        ele.documentType === documentType &&
        ele.personId === personId
      ) {
        found = true;
      }
    });
  }
  return found;
};

export const personHasIncome = (application, documents, base, index) => {
  const programCode = application.programCode;
  if (!programCode) return false;
  const personId = getPersonId(application, base, index);
  const appBase = getBase(application, base, index);

  return (
    appBase.doYouReceiveIncomeCode === NO_OPTION ||
    appBase.didYouFileFederalIncomeTaxReturnCode === NO_OPTION ||
    hasDocument(
      documents,
      personId,
      DOCUMENT_TYPES.federalTaxReturn[programCode].code,
    ) ||
    hasDocument(
      documents,
      personId,
      DOCUMENT_TYPES.proofOfIncome[programCode].code,
    ) ||
    hasDocument(
      documents,
      personId,
      DOCUMENT_TYPES.signature4506T[programCode].code,
    ) ||
    hasDocument(
      documents,
      personId,
      DOCUMENT_TYPES.signatureNoIncome[programCode].code,
    )
  );
};
