export default {
  proofOfIdentification: {
    10: {
      code: '10010',
      keywords: {
        DriversLicense: '10001',
      },
    },
    11: {
      code: '10010',
      keywords: {
        DriversLicense: '10001',
      },
    },
  },
  primaryResidence: {
    10: {
      code: '10040',
    },
    11: {
      code: '10040',
    },
  },
  proofOfInsurance: {
    10: {
      code: '10020',
    },
    11: { code: '10020' },
  },
  proofOfOwnership: {
    10: {
      code: '10030',
      traditionalDocumentsCodes: ['10021', '10022', '10023', '10024'],
      nonTraditionalDocumentsCodes: ['10025', '10026', '10027'],
    },
    11: {
      code: '10030',
      traditionalDocumentsCodes: ['10021', '10022', '10023', '10024'],
      nonTraditionalDocumentsCodes: ['10025', '10026', '10027'],
    },
  },
  proofOfCitizenship: {
    10: {
      code: '10015',
    },
    11: {
      code: '10015',
    },
  },
  proofOfDisability: {
    10: {
      code: '10065',
    },
    11: {
      code: '10065',
    },
  },
  // Unused for FL
  proofOfLandOwnership: {
    // 10: {
    //   code: '7410',
    // },
    // 11: {
    //   code: '7410',
    // },
  },
  // Unused for FL
  tenantLeaseAgreement: {
    // 11: {
    //   code: '7400',
    // },
  },
  // proofOfMobileHomeOwnership: {
  //   code: '4008'
  // },
  proofOfPrimaryResidence: {
    10: {
      code: '10040',
    },
    11: {
      code: '10040',
    },
  },
  proofOfPropertyDamage: {
    10: {
      code: '10050',
      keywords: {
        proofOfInsurancePayout: '10065',
      },
    },
    11: {
      code: '10050',
      keywords: {
        proofOfInsurancePayout: '10065',
      },
    },
  },
  proofOfIncome: {
    10: {
      code: '10060',
    },
    11: {
      code: '10060',
    },
  },
  stateTaxReturn: {
    10: {
      code: '10070',
    },
    11: {
      code: '10070',
    },
  },
  federalTaxReturn: {
    10: {
      code: '10080',
    },
    11: {
      code: '10080',
    },
  },
  signatureNoIncome: {
    10: {
      code: '10210',
    },
    11: {
      code: '10210',
    },
  },
  signature4506T: {
    10: {
      code: '10230',
    },
    11: {
      code: '10230',
    },
  },
  signatureLawfulPresence: {
    10: {
      code: '10200',
    },
    11: {
      code: '10200',
    },
  },
  signatureSubrogationAgreement: {
    10: {
      code: '10250',
    },
    11: {
      code: '10250',
    },
  },
  signatureNoInsurance: {
    10: {
      code: '10220',
    },
    11: {
      code: '10220',
    },
  },
  communicationDesigneeAuthorization: {
    10: {
      code: '10240',
    },
    11: {
      code: '10240',
    },
  },
  powerOfAttorney: {
    10: {
      code: '10100',
    },
    11: {
      code: '10100',
    },
  },
  lienDocument: {
    10: {
      code: '10090',
    },
    11: {
      code: '10090',
    },
  },
  docusignNoIncome: {
    10: {
      code: '10310',
    },
    11: {
      code: '10310',
    },
  },
  docusign4506T: {
    10: {
      code: '10330',
    },
    11: {
      code: '10330',
    },
  },
  docusignLawfulPresence: {
    10: {
      code: '10300',
    },
    11: {
      code: '10300',
    },
  },
  docusignSubrogationAgreement: {
    10: {
      code: '10350',
    },
    11: {
      code: '10350',
    },
  },
  docusignNoInsurance: {
    10: {
      code: '10320',
    },
    11: {
      code: '10320',
    },
  },
  docusignCommunicationDesignee: {
    10: {
      code: '10340',
    },
    11: {
      code: '10340',
    },
  },
};
