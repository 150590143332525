import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  AppSetInitilized: [null],
  AppSetHasCamera: ['hasCamera'],
  AppSetStatus: ['status'],
  AppSendLogs: ['applicationId'],
  AppSetLog: ['log'],
});

export const AppTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  initilized: false,
  hasCamera: null,
  data: null,
  status: {},
  log: {},
  fetching: null,
  error: null,

  // Used to Validate PIN
  temp: null,
});

/* ------------- Reducers ------------- */

export const setInitilized = (state: Object) =>
  state.merge({ initilized: true });

export const setHasCamera = (state: Object, { hasCamera }: Object) =>
  state.merge({ hasCamera });

export const setStatus = (state: Object, { status }: Object) =>
  state.merge({ status });

export const setLog = (state: Object, { log }: Object) => state.merge({ log });
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.APP_SET_INITILIZED]: setInitilized,
  [Types.APP_SET_HAS_CAMERA]: setHasCamera,
  [Types.APP_SET_STATUS]: setStatus,
  [Types.APP_SET_LOG]: setLog,
  // [Types.APP_SEND_LOGS]: setStatus,
});

/* ------------- Selectors ------------- */
