/* eslint-disable global-require */
// leave off @2x/@3x
const images = {
  logo: require('../Images/icon.png'),
  hudWhite: require('../Images/hud-white.png'),
  triangleFooter: require('../Images/triangle-logo.png'),
  docIcon: require('../Images/Icons/doc-icon.png'),
  docxIcon: require('../Images/Icons/docx-icon.png'),
  pdfIcon: require('../Images/Icons/pdf-icon.png'),
};

export default images;
