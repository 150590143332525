import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image } from 'react-native';
import styles from './Styles/FooterStyles';
import ExamplesRegistry from '../Services/ExamplesRegistry';
import { Images } from '../Themes';

// Note that this file (App/Components/Footer) needs to be
// imported in your app somewhere, otherwise your component won't be
// compiled and added to the examples dev screen.

const Footer = (props) => (
  <View style={styles.background}>
    <View style={styles.logoContainer}>
      <Image style={styles.logo} source={Images.triangleFooter} />
    </View>
    <View style={styles.logoContainer}>
      <Image style={styles.logo} source={Images.hudWhite} />
    </View>
  </View>
);

export default Footer;
