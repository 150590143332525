/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
import './Config';
import { StatusBar } from 'expo-status-bar';
import ExpoPixi from 'expo-pixi';
import * as Sentry from 'sentry-expo';

import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  Animated,
  Easing,
} from 'react-native';
import { PersistGate } from 'redux-persist/integration/react';
// eslint-disable-next-line import/no-unresolved
// import { CODEPUSH_KEY } from '@env';
import { Provider } from 'react-redux';

import { AppLoading } from 'expo';
import {
  useFonts,
  Lato_100Thin,
  Lato_100Thin_Italic,
  Lato_300Light,
  Lato_300Light_Italic,
  Lato_400Regular,
  Lato_400Regular_Italic,
  Lato_700Bold,
  Lato_700Bold_Italic,
  Lato_900Black,
  Lato_900Black_Italic,
} from '@expo-google-fonts/lato';

// import codePush from 'react-native-code-push';

// import faIconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
// import ionIconFont from 'react-native-vector-icons/Fonts/Ionicons.ttf';
// import matIconFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
// import { FontAwesome as Icon } from "@expo/vector-icons";

// import isDev from './Lib/isDev';
import './Themes/BootstrapCss';

import RootContainer from './Containers/RootContainer';
import createStore from './Redux';
import ScanBarcode from './Components/ScanBarcode';
import { Metrics } from './Themes';
// import CameraScreen from './Containers/CameraScreen';
// import ScanBarcode from './Components/ScanBarcode';
// import TakePicture from './Components/TakePicture';

// if (Platform.OS === 'web') {
//   // Inject Font Awesome
//   const iconFontStyles = `@font-face {
//   src: url(${faIconFont});
//   font-family: FontAwesome;
// }
// @font-face {
//   src: url(${ionIconFont});
//   font-family: Ionicons;
// }
// @font-face {
//   src: url(${matIconFont});
//   font-family: MaterialIcons;
// }`;

//   // Create stylesheet
//   const style = document.createElement('style');
//   style.type = 'text/css';
//   if (style.styleSheet) {
//     style.styleSheet.cssText = iconFontStyles;
//   } else {
//     style.appendChild(document.createTextNode(iconFontStyles));
//   }
//   // Inject stylesheet
//   document.head.appendChild(style);
// }

Sentry.init({
  dsn:
    'https://3f4d12ef25db4dad99f1b09b0cfded25@o562593.ingest.sentry.io/5701331',
  enableInExpoDevelopment: true,
  debug: true, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});

// Create history once
export const history =
  Platform.OS === 'web'
    ? require('history').createBrowserHistory()
    : require('history').createMemoryHistory();

// create our store
const { store, persistor } = createStore(history);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 300,
    height: 300,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  button: {
    borderRadius: 3,
    padding: 20,
    marginVertical: 10,
    marginTop: 10,
    backgroundColor: '#1B95E0',
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
});

export default function App() {
  let [fontsLoaded] = useFonts({
    Lato_100Thin,
    Lato_100Thin_Italic,
    Lato_300Light,
    Lato_300Light_Italic,
    Lato_400Regular,
    Lato_400Regular_Italic,
    Lato_700Bold,
    Lato_700Bold_Italic,
    Lato_900Black,
    Lato_900Black_Italic,
  });

  let fontSize = 24;
  let paddingVertical = 6;

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  // return (
  //   <View style={styles.container}>
  //     <ExpoPixi.Sketch
  //       // ref={(ref) => (this.sketch = ref)}
  //       // onChange={handleSigned}
  //       style={{
  //         width: Metrics.screenWidth,
  //         height: 200,
  //         borderWidth: 1,
  //         borderStyle: 'solid',
  //       }}
  //       onReady={() => {
  //         console.log('READY');
  //       }}
  //       strokeWidth={3} // thickness of the brush
  //       strokeAlpha={0.5} // opacity of the brush
  //       strokeColor={0x0000ff}
  //     />
  //   </View>
  // );
  // return (
  //   <View style={styles.container}>
  //     <TakePicture
  //       onComplete={(images) => {
  //         console.log(images);
  //       }}
  //       onClose={() => {}}
  //       multiple
  //     />
  //   </View>
  // );
  // return (
  //   <View style={styles.container}>
  //     <ScanBarcode
  //       onComplete={(asd) => {
  //         console.log("App -> Scan", asd);
  //       }}
  //       onClose={() => {}}
  //     />
  //   </View>
  // );
  return (
    <Provider store={store}>
      <PersistGate loading={<AppLoading />} persistor={persistor}>
        <RootContainer history={history} />
      </PersistGate>
    </Provider>
  );

  return (
    <Provider store={store}>
      <PersistGate loading={<AppLoading />} persistor={persistor}>
        <View style={styles.container}>
          <View
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_100Thin',
              }}
            >
              Lato Thin
            </Text>

            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_100Thin_Italic',
              }}
            >
              Lato Thin Italic
            </Text>

            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_300Light',
              }}
            >
              Lato Light
            </Text>

            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_300Light_Italic',
              }}
            >
              Lato Light Italic
            </Text>

            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_400Regular',
              }}
            >
              Lato Regular
            </Text>

            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_400Regular_Italic',
              }}
            >
              Lato Italic
            </Text>

            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_700Bold',
              }}
            >
              Lato Bold
            </Text>

            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_700Bold_Italic',
              }}
            >
              Lato Bold Italic
            </Text>

            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_900Black',
              }}
            >
              Lato Black
            </Text>

            <Text
              style={{
                fontSize,
                paddingVertical,
                // Note the quoting of the value for `fontFamily` here; it expects a string!
                fontFamily: 'Lato_900Black_Italic',
              }}
            >
              Lato Black Italic
            </Text>
          </View>
          <StatusBar style="auto" />
        </View>
      </PersistGate>
    </Provider>
  );
}

// const dev = isDev();
// // Don't use CodePush for DEV
// if (
//   (Platform.OS === 'ios' || Platform.OS === 'android') &&
//   !dev &&
//   CODEPUSH_KEY
// ) {
//   console.log(`loading codepush: ${CODEPUSH_KEY}`);
//   App = codePush({
//     checkFrequency: codePush.CheckFrequency.ON_APP_RESUME,
//     deploymentKey: CODEPUSH_KEY,
//     // updateDialog: true,
//     installMode: codePush.InstallMode.IMMEDIATE,
//   })(App);
// }

// export default App;
