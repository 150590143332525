import { Platform } from 'react-native';
import * as R from 'ramda';
import diff from 'deep-diff';
import moment from 'moment';

import { push } from 'connected-react-router';

import ApplicationActions, {
  checkRentalProgram,
} from '../Redux/ApplicationRedux';
import PersistActions from '../Redux/PersistRedux';
import DocumentActions from '../Redux/DocumentRedux';
import { getOptions, getDocTypes, getScreeningQuestions } from './OptionsSagas';

import { isNumeric, hasDocument } from '../Lib/Utils';

import {
  ORGIN_CODE_MOBILE,
  ORGIN_CODE_WEB,
  NO_OPTION,
  TRUE_OPTION,
  STATUS_IN_PROGRESS,
  VENDOR_NAME,
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE, {
  DELETE_STRUCTURE,
} from '../Config/DataStructureConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import validateTokenAsync from './AccountValidateTokenThunk';
import {
  getDocTypesAsync,
  getOptionsAsync,
  getScreeningQuestionsAsync,
} from './OptionsThunk';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;

const LAND_OWNER_BASE = DATA_STRUCTURE.landOwner;
const POA_BASE = DATA_STRUCTURE.powerOfAttorney;
const COMMUNICATIONS_DESIGNEE_BASE = DATA_STRUCTURE.communicationsDesignee;

const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.household_members;
const TENANTS_BASE = DATA_STRUCTURE.tenants;
const LIEN_HOLDERS_BASE = DATA_STRUCTURE.lien_holders;
const INSURANCE_BASE = DATA_STRUCTURE.insurance;
const ADDITIONAL_FUNDS_BASE = DATA_STRUCTURE.additionalFunds;
const DAMAGED_ADDRESS_BASE = DATA_STRUCTURE.damagedAddress;

export const getApplicationsAsync = () => async (dispatch, getState, api) => {
  const { account, tokenId, token } = getState().persist;

  const userId = account?.userId;

  const loggedIn = await dispatch(validateTokenAsync, { force: false });
  if (!loggedIn) {
    return false;
  }

  let results = '';
  try {
    results = await api.getApplications(userId, tokenId, token);
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      results = error;
    }
  }
  if (R.path(['data', 'payload', 0, 'applicationId'], results)) {
    dispatch(ApplicationActions.ApplicationSuccess(results.data.payload));
    return results.data.payload;
  }
  if (R.path(['data', 'payload'], results)) {
    dispatch(ApplicationActions.ApplicationSuccess([]));
  } else if (R.path(['data', 'error', 0, 'message'], results)) {
    dispatch(
      ApplicationActions.ApplicationFailure(results.data.error[0].message),
    );
  } else {
    dispatch(ApplicationActions.ApplicationFailure('Unknown Error'));
  }
};

export const getApplicationAsync = ({
  applicationId,
  diffApplication,
}) => async (dispatch, getState, api) => {
  const {
    account,
    tokenId,
    token,
    programCode: exisitngProgramCode,
  } = getState().persist;
  const userId = account?.userId;

  const loggedIn = await dispatch(validateTokenAsync, { force: false });
  if (!loggedIn) {
    return false;
  }

  let results = '';
  try {
    console.log('api.getApplication', userId, tokenId, token, applicationId);
    results = await api.getApplication(userId, tokenId, token, applicationId);
  } catch (error) {
    console.log('Error api.getApplication', error);
    if (R.path(['data', 'payload', 0, 'id'], error)) {
      results = error;
    }
  }

  console.log(
    `api.getApplication results: ${JSON.stringify(results, null, 2)}`,
  );

  if (R.path(['data', 'payload', 0, 'id'], results)) {
    console.log('api.getApplication success');
    // console.log('get application', JSON.stringify(results.data.payload[0], null, 2));
    const loadedApplication = results.data.payload[0];

    // Check to see if we need to reload docTypes and screeningQuestions
    if (exisitngProgramCode !== loadedApplication.programCode) {
      console.log('starting getOptions', loadedApplication.programCode);
      await getOptions({
        programCode: loadedApplication.programCode,
      });
      console.log('ending getOptions');

      console.log(
        'starting getScreeningQuestions',
        loadedApplication.programCode,
      );
      await getScreeningQuestions({
        programCode: loadedApplication.programCode,
      });
      console.log('ending getScreeningQuestions');

      console.log('starting getDocTypes', loadedApplication.programCode);
      await getDocTypes({
        programCode: loadedApplication.programCode,
      });
      console.log('ending getDocTypes');
    }

    dispatch(PersistActions.PersistSetApplication(loadedApplication));
    dispatch(ApplicationActions.ApplicationSuccess(loadedApplication));

    if (diffApplication) {
      const diffResults = diff(loadedApplication, diffApplication);
      if (diffResults) {
        diffResults.forEach((ele) => {
          const path = ele.path.join('/');
          switch (ele.kind) {
            case 'N':
              console.log(`Added: ${path} with ${ele.rhs}`);
              break;
            case 'D':
              console.log(`Deleted: ${path} was ${ele.lhs}`);
              break;
            case 'E':
              console.log(`Changed: ${path} from ${ele.lhs} to ${ele.rhs}`);
              break;
            case 'A':
              console.log(
                `Array change: ${path} index ${ele.index} ${JSON.stringify(
                  ele.item,
                )}`,
              );
              break;
            default:
              break;
          }
        });
      }
    }
    return loadedApplication;
  }
  if (
    typeof results.data === 'string' &&
    results.data.includes(
      'Successfully found Token record with this @UserId, @TokenId, and @Token, however, it has already expired.',
    )
  ) {
    console.log(
      'Successfully found Token record with this @UserId, @TokenId, and @Token, however, it has already expired.',
    );
    // Force Logout
    dispatch(PersistActions.PersistReset());
    // Go Home
    dispatch(push('/'));
    // Send Error Message
    ApplicationActions.ApplicationFailure(
      'Successfully found Token record with this @UserId, @TokenId, and @Token, however, it has already expired.',
    );
    return {
      error:
        'Successfully found Token record with this @UserId, @TokenId, and @Token, however, it has already expired.',
    };
  }
  if (
    R.path(['data', 'error', 0, 'message'], results) ===
    'Failed to find Token record with this @UserId, @TokenId, and @Token.'
  ) {
    console.log(results.data.error[0].message);
    dispatch(
      ApplicationActions.ApplicationFailure(results.data.error[0].message),
    );
    return { error: results.data.error[0].message };
  }
  if (R.path(['data', 'error', 0, 'message'], results)) {
    dispatch(
      ApplicationActions.ApplicationFailure(results.data.error[0].message),
    );
    return { error: results.data.error[0].message };
  }
  dispatch(ApplicationActions.ApplicationFailure('Unknown Error'));
  return { error: 'Unknown Error' };
};

export const createApplicationAsync = ({ programCode }) => async (
  dispatch,
  getState,
  api,
) => {
  console.log(
    '🚀 ~ file: createApplicationAsync ~ line 223 ~ programCode',
    programCode,
  );
  const { isKiosk } = getState().persist;
  const locale = getState()?.i18n?.locale || 'en';

  // const newId = Object.keys(applications).length + 1 || 1;
  const newId = new Date().getTime();

  const newApplication = {};

  newApplication.id = isKiosk ? `OFFLINE_${newId}` : '-1';
  newApplication.programCode = programCode;
  newApplication.applicant = { id: '-1' };
  // newApplication.damagedAddress = { id: '-1' };

  newApplication.originCode =
    Platform.OS === 'web' ? ORGIN_CODE_WEB : ORGIN_CODE_MOBILE;

  newApplication.languageCode = locale === 'en' ? '1' : '2';
  newApplication.status = STATUS_IN_PROGRESS;

  console.log('created Application Async: ', newApplication);

  // Load Options by Program Code
  console.log('starting getOptions', programCode);
  await dispatch(getOptionsAsync({ programCode }));
  console.log('ending getOptions');

  console.log('starting getScreeningQuestions', programCode);
  await dispatch(getScreeningQuestionsAsync({ programCode }));
  console.log('ending getScreeningQuestions');

  console.log('starting getDocTypes', programCode);
  await dispatch(getDocTypesAsync({ programCode }));
  console.log('ending getDocTypes');

  const { allOptions, allDocTypes, allScreeningQuestions } = getState().persist;

  if (isKiosk) {
    newApplication.created = new Date().getTime();
    // Set Options By Program Code
    await dispatch(
      PersistActions.PersistSetOptions(allOptions[programCode][locale]),
    );
    await dispatch(
      PersistActions.PersistSetDocTypes(allDocTypes[programCode][locale]),
    );
    await dispatch(
      PersistActions.PersistSetScreeningQuestions(
        allScreeningQuestions[programCode][locale],
      ),
    );
    await dispatch(PersistActions.PersistSetProgramCode(programCode));

    await dispatch(PersistActions.PersistSetApplication(newApplication));
    await dispatch(ApplicationActions.ApplicationSuccess(newApplication));
    return newApplication;
  }
  // First set default values
  await dispatch(
    PersistActions.PersistSetOptions(allOptions[programCode][locale]),
  );
  await dispatch(
    PersistActions.PersistSetDocTypes(allDocTypes[programCode][locale]),
  );
  await dispatch(
    PersistActions.PersistSetScreeningQuestions(
      allScreeningQuestions[programCode][locale],
    ),
  );

  console.log('1');
  await dispatch(PersistActions.PersistSetApplication(newApplication));
  console.log('2');
  dispatch(DocumentActions.DocumentSuccess([]));
  console.log('3');
  await dispatch(ApplicationActions.ApplicationSuccess(newApplication));
  console.log('4');

  console.log('returning', newApplication);
  return newApplication;
};

export const updateApplicationAsync = ({ application }) => async (
  dispatch,
  getState,
  api,
) => {
  const { account, tokenId, token, isKiosk } = getState().persist;
  const userId = account?.userId;
  const locale = getState()?.i18n?.locale || 'en';

  // const newId = Object.keys(applications).length + 1 || 1;
  const newId = new Date().getTime();

  console.log('updateApplication async', JSON.stringify(application, null, 2));
  const newApplication = JSON.parse(JSON.stringify(application));
  console.log('newApplication', newApplication);

  // Set Create Row Defaults
  if (!newApplication.id)
    newApplication.id = isKiosk ? `OFFLINE_${newId}` : '-1';

  if (!newApplication[APPLICANT_BASE]) {
    newApplication[APPLICANT_BASE] = { id: '-1' };
  }
  if (!newApplication[DAMAGED_ADDRESS_BASE]) {
    newApplication[DAMAGED_ADDRESS_BASE] = { id: '-1' };
  }
  if (!newApplication.languageCode) {
    newApplication.languageCode = locale === 'en' ? '1' : '2';
  }

  if (!newApplication.status) {
    newApplication.status = STATUS_IN_PROGRESS;
  }

  // Hard Code Origin Code
  newApplication.originCode =
    Platform.OS === 'web' ? ORGIN_CODE_WEB : ORGIN_CODE_MOBILE;
  // Hard Code Vendor Name
  newApplication.vendorName = VENDOR_NAME;

  const fieldsRequiringId = [
    APPLICANT_BASE,
    CO_APPLICANT_BASE,
    DAMAGED_ADDRESS_BASE,
    LAND_OWNER_BASE,
    POA_BASE,
    COMMUNICATIONS_DESIGNEE_BASE,
  ];

  fieldsRequiringId.forEach((ele) => {
    if (newApplication[ele] && !newApplication[ele].id) {
      newApplication[ele].id = '-1';
    }
  });

  const subfieldsRequiringId = [
    HOUSEHOLD_MEMBERS_BASE,
    LIEN_HOLDERS_BASE,
    ADDITIONAL_FUNDS_BASE,
    TENANTS_BASE,
    COMMUNICATIONS_DESIGNEE_BASE,
  ];
  console.log('SETTING DEFAULTS newApplication', newApplication);
  subfieldsRequiringId.forEach((base) => {
    console.log('base', base);
    if (
      newApplication[base] &&
      typeof newApplication[base].forEach === 'function'
    ) {
      newApplication[base].forEach((ele, idx) => {
        if (!newApplication[base][idx].id) {
          newApplication[base][idx].id = '-1';
        }
      });
    }
  });

  if (
    newApplication[DAMAGED_ADDRESS_BASE] &&
    newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE]
  ) {
    if (
      newApplication[DAMAGED_ADDRESS_BASE] &&
      typeof newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE].forEach ===
        'function'
    ) {
      newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE].forEach(
        (ele, idx) => {
          if (!newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE][idx].id) {
            newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE][idx].id = '-1';
          }
        },
      );
    }
  }

  if (isKiosk || !userId) {
    newApplication.updated = new Date().getTime();
    dispatch(PersistActions.PersistSetApplication(newApplication));
    dispatch(ApplicationActions.ApplicationSuccess(newApplication));
    return newApplication;
  }
  const loggedIn = await dispatch(validateTokenAsync, { force: false });
  if (!loggedIn) {
    return false;
  }

  // Presave Application in persist
  dispatch(PersistActions.PersistSetApplication(newApplication));

  console.log('start update', JSON.stringify(newApplication, null, 2));

  let results = '';
  try {
    results = await api.updateApplication(
      userId,
      tokenId,
      token,
      newApplication,
    );
    console.log('results.data', JSON.stringify(results.data, null, 2));
  } catch (error) {
    console.log('error', error);
    if (R.path(['data', 'payload', 0, 'id'], error)) {
      results = error;
    }
  }

  if (R.path(['data', 'error', 0, 'message'], results)) {
    dispatch(
      ApplicationActions.ApplicationFailure(results.data.error[0].message),
    );
    return { error: results.data.error[0].message };
  }
  if (!R.path(['data', 'payload', 0, 'applicationId'], results)) {
    dispatch(ApplicationActions.ApplicationFailure('Unknown Error'));
    return { error: 'Unknown Error' };
  }
  const applicationId = R.path(
    ['data', 'payload', 0, 'applicationId'],
    results,
  );
  console.log(`update success: applicationId ${applicationId}`);
  const loadedApplication = await dispatch(
    getApplicationAsync({
      applicationId,
      diffApplication: newApplication,
    }),
  );
  return loadedApplication;
};

export const deleteRecordAsync = ({ recordType, memberId, idx }) => async (
  dispatch,
  getState,
  api,
) => {
  const { application, account, tokenId, token } = getState().persist;
  const userId = account?.userId;

  console.log('deleteRecord', recordType, memberId, idx);

  const newApplication = JSON.parse(JSON.stringify(application));

  if (!isNumeric(newApplication.id)) {
    // const base = DATA_STRUCTURE[recordType];
    // console.log(base, recordType, memberId, idx);
    console.log(JSON.stringify(newApplication, null, 2));
    if (idx === undefined || newApplication[recordType].length === 1) {
      delete newApplication[recordType];
    } else {
      newApplication[recordType].splice(idx, 1);
    }

    console.log(
      'saving new application',
      JSON.stringify(newApplication, null, 2),
    );

    dispatch(PersistActions.PersistSetApplication(newApplication));
    dispatch(ApplicationActions.ApplicationSuccess(newApplication));
    return newApplication;
  }
  const loggedIn = await dispatch(validateTokenAsync, { force: false });
  if (!loggedIn) {
    return false;
  }

  console.log(
    'api.deleteRecord',
    DELETE_STRUCTURE[recordType],
    userId,
    tokenId,
    token,
    application.id,
    memberId,
  );
  const results = await api.deleteRecord(
    DELETE_STRUCTURE[recordType],
    userId,
    tokenId,
    token,
    application.id,
    memberId,
  );
  // console.log("delete record results", JSON.stringify(results, null, 2));
  if (R.path(['data', 'payload', 0, 'applicationId'], results)) {
    await dispatch(getApplicationAsync({ applicationId: application.id }));
    return results.data.payload;
  }
  dispatch(ApplicationActions.ApplicationFailure('Error Deleting Record'));
  return false;
};

export const createDocusignAsync = ({ application }) => async (
  dispatch,
  getState,
  api,
) => {
  const { account, tokenId, token } = getState().persist;
  const { documents } = getState().document;
  const userId = account?.userId;

  const loggedIn = await dispatch(validateTokenAsync, { force: false });
  if (!loggedIn) {
    return false;
  }

  const locale = getState()?.i18n?.locale || 'en';

  const isRentalProgram = checkRentalProgram(application);

  const programCode = application.programCode;
  if (Platform.OS === 'web') {
    const body = {
      id: '-1',
      programCode: application.programCode,
      locale: locale || 'en',
      applicationId: application.id,
      documents: [],
    };

    const applicantDocuments = {
      personId: application[APPLICANT_BASE].id,
      documentTypeCode: [],
      envelopeId: '',
      returnURL: 'https://example.com',
    };

    if (!isRentalProgram) {
      if (application[APPLICANT_BASE].doYouReceiveIncomeCode === NO_OPTION) {
        // No Proof of Income Uploaded.
        applicantDocuments.documentTypeCode.push(
          DOCUMENT_TYPES.docusignNoIncome[programCode].code,
        );
      } else if (
        application[APPLICANT_BASE].isTaxReturn4506TRequested === TRUE_OPTION
      ) {
        // 4506T Form
        applicantDocuments.documentTypeCode.push(
          DOCUMENT_TYPES.docusign4506T[programCode].code,
        );
      } else if (
        !hasDocument(
          documents,
          application[APPLICANT_BASE].id,
          DOCUMENT_TYPES.proofOfIncome[programCode].code,
        )
      ) {
        // No Proof of Income Uploaded.
        applicantDocuments.documentTypeCode.push(
          DOCUMENT_TYPES.docusignNoIncome[programCode].code,
        );
      }
    }

    // If there are no insurance entities
    if (
      application.hadInsuranceCode === NO_OPTION ||
      !application[INSURANCE_BASE]
    ) {
      applicantDocuments.documentTypeCode.push(
        DOCUMENT_TYPES.docusignNoInsurance[programCode].code,
      );
    }

    // If there is a comm designee
    if (application.communicationsDesignee) {
      applicantDocuments.documentTypeCode.push(
        DOCUMENT_TYPES.docusignCommunicationDesignee[programCode].code,
      );
    }

    // always add subrogation agreement
    applicantDocuments.documentTypeCode.push(
      DOCUMENT_TYPES.docusignSubrogationAgreement[programCode].code,
    );

    // always add lawful presence
    applicantDocuments.documentTypeCode.push(
      DOCUMENT_TYPES.docusignLawfulPresence[programCode].code,
    );

    body.documents.push(applicantDocuments);

    let coapplicantDocuments = null;
    if (application[CO_APPLICANT_BASE]) {
      coapplicantDocuments = {
        personId: application[CO_APPLICANT_BASE].id,
        documentTypeCode: [],
        envelopeId: '',
        returnURL: 'https://example.com',
      };
      if (!isRentalProgram) {
        if (
          application[CO_APPLICANT_BASE].doYouReceiveIncomeCode === NO_OPTION
        ) {
          // No Proof of Income Uploaded.
          coapplicantDocuments.documentTypeCode.push(
            DOCUMENT_TYPES.docusignNoIncome[programCode].code,
          );
        } else if (
          application[CO_APPLICANT_BASE].isTaxReturn4506TRequested ===
          TRUE_OPTION
        ) {
          coapplicantDocuments.documentTypeCode.push(
            DOCUMENT_TYPES.docusign4506T[programCode].code,
          );
        } else if (
          !hasDocument(
            documents,
            application[CO_APPLICANT_BASE].id,
            DOCUMENT_TYPES.proofOfIncome[programCode].code,
          )
        ) {
          // No Proof of Income Uploaded.
          coapplicantDocuments.documentTypeCode.push(
            DOCUMENT_TYPES.docusignNoIncome[programCode].code,
          );
        }
      }

      if (coapplicantDocuments.documentTypeCode.length > 0) {
        body.documents.push(coapplicantDocuments);
      }
    }

    if (
      application[HOUSEHOLD_MEMBERS_BASE] &&
      application[HOUSEHOLD_MEMBERS_BASE].length &&
      application[HOUSEHOLD_MEMBERS_BASE].length > 0
    ) {
      application[HOUSEHOLD_MEMBERS_BASE].forEach((member) => {
        const householdMemberDocuments = {
          personId: member.id,
          documentTypeCode: [],
          envelopeId: '',
          returnURL: 'https://example.com',
        };
        const dateOfBirth = moment(member.dateOfBirth, 'M/D/YYYY');
        const age = member.dateOfBirth
          ? moment().diff(dateOfBirth, 'years')
          : 0;
        if (!isRentalProgram && age >= 18) {
          if (member.doYouReceiveIncomeCode === NO_OPTION) {
            // No Proof of Income Uploaded.
            householdMemberDocuments.documentTypeCode.push(
              DOCUMENT_TYPES.docusignNoIncome[programCode].code,
            );
          } else if (member.isTaxReturn4506TRequested === TRUE_OPTION) {
            householdMemberDocuments.documentTypeCode.push(
              DOCUMENT_TYPES.docusign4506T[programCode].code,
            );
          } else if (
            !hasDocument(
              documents,
              member.id,
              DOCUMENT_TYPES.proofOfIncome[programCode].code,
            )
          ) {
            // No Proof of Income Uploaded.
            householdMemberDocuments.documentTypeCode.push(
              DOCUMENT_TYPES.docusignNoIncome[programCode].code,
            );
          }
        }
        if (householdMemberDocuments.documentTypeCode.length > 0) {
          body.documents.push(householdMemberDocuments);
        }
      });
    }

    console.log(
      'api.createDocusign',
      userId,
      tokenId,
      token,
      JSON.stringify(body, null, 2),
    );
    const results = await api.createDocusign(userId, tokenId, token, body);
    console.log(JSON.stringify(results, null, 2));
    if (R.path(['data', 'success'], results) === 'true') {
      // yield put(ApplicationActions.ApplicationSuccess(application));

      // TODO: Set Status Complete
      await dispatch(
        updateApplicationAsync({
          application,
        }),
      );
    } else if (R.path(['data', 'error', 0, 'message'], results)) {
      dispatch(
        ApplicationActions.ApplicationFailure(results.data.error[0].message),
      );
    } else {
      dispatch(
        ApplicationActions.ApplicationFailure('Error Creating Docusign'),
      );
    }
    // NOT WEB
  } else if (!isNumeric(application.id)) {
    // Offline
    dispatch(ApplicationActions.ApplicationSuccess(application));
    return application;
  } else {
    dispatch(
      updateApplicationAsync({
        application,
      }),
    );
    return application;
  }
};

/*
 * When we set the application, save it in the persistant store as well.
 */
export const saveApplicationIdAsync = ({ application }) => async (
  dispatch,
  getState,
) => {
  if (application && application.id) {
    const { applications } = getState().persist;
    if (!applications[application.id]) {
      applications[application.id] = {};
    }
    dispatch(PersistActions.PersistSetApplications(applications));
    dispatch(PersistActions.PersistSetApplication(application));
    dispatch(PersistActions.PersistSetApplicationId(application.id));
    return application;
  }
  dispatch(PersistActions.PersistSetApplication(null));
  dispatch(PersistActions.PersistSetApplicationId(null));
  return false;
};
