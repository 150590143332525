import { StyleSheet } from 'react-native';
import { Metrics, Fonts, Colors } from '../../Themes';

export default StyleSheet.create({
  fullButton: {
    marginTop: Metrics.baseMargin,
    marginBottom: 0,
    borderRadius: 5,
    backgroundColor: Colors.buttonBlue, // buttonGrey
  },
  fullButtonDisabled: {
    marginTop: Metrics.baseMargin,
    marginBottom: 0,
    borderRadius: 5,
    borderColor: Colors.buttonBorderDisabled,
    backgroundColor: Colors.buttonDisabled,
  },
  fullButtonText: {
    margin: 10,
    textAlign: 'center',
    color: Colors.buttonText,
    fontSize: 20,
    lineHeight: 24,
    fontFamily: Fonts.type.bold,
  },
  fullButtonTextDisabled: {
    margin: 10,
    textAlign: 'center',
    color: Colors.buttonText,
    fontSize: Fonts.size.pt44,
    fontFamily: Fonts.type.bold,
  },

  smallButton: {
    marginTop: Metrics.baseMargin,
    marginBottom: 0,
    borderRadius: 5,
    backgroundColor: Colors.buttonBlue,
  },
  smallButtonDisabled: {
    marginTop: Metrics.baseMargin,
    marginBottom: 0,
    borderRadius: 5,
    borderColor: Colors.buttonBorderDisabled,
    backgroundColor: Colors.buttonDisabled,
  },
  smallButtonText: {
    margin: 5,
    textAlign: 'center',
    color: Colors.buttonText,
    fontSize: Fonts.size.pt36,
    fontFamily: Fonts.type.bold,
  },
  smallButtonTextDisabled: {
    margin: 10,
    textAlign: 'center',
    color: Colors.buttonText,
    fontSize: Fonts.size.pt44,
    fontFamily: Fonts.type.bold,
  },

  linkButton: {
    marginTop: Metrics.baseMargin,
    marginBottom: 0,
  },
  linkButtonDisabled: {
    marginTop: Metrics.baseMargin,
    marginBottom: 0,
  },
  linkButtonText: {
    margin: 10,
    textAlign: 'center',
    color: Colors.buttonGrey,
    textDecorationLine: 'underline',
    fontSize: Fonts.size.pt36,
    fontFamily: Fonts.type.bold,
  },
  linkButtonTextDisabled: {
    margin: 10,
    textAlign: 'center',
    color: Colors.buttonGrey,
    textDecorationLine: 'underline',
    fontSize: Fonts.size.pt36,
    fontFamily: Fonts.type.bold,
  },
});
