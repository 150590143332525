import React, { useEffect, useState } from 'react';
import {
  Text,
  View,
  StatusBar,
  AppState,
  ActivityIndicator,
  Platform,
} from 'react-native';
import { useDispatch } from 'react-redux';
import * as Updates from 'expo-updates';

import LoadingScreen from './LoadingScreen';

import SafeAera from '../Components/SafeAera';
import TopNavPlaceholder from '../Components/TopNavPlaceholder';

import Navigation from '../Navigation';

// Styles
import styles from './Styles/RootContainerStyles';
import { Colors, Metrics } from '../Themes';
import { startupAsync } from '../Sagas/StartupThunk';

export default function RootContainer(props) {
  const dispatch = useDispatch();

  const [appState, setAppState] = useState(AppState.currentState);
  const [initilized, setInitilized] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      // This will trigger when resuming the application
      const handleAppStateChange = async (nextAppState) => {
        if (
          appState.match(/inactive|background/) &&
          nextAppState === 'active'
        ) {
          console.log('App has come to the foreground.');
          try {
            const update = await Updates.checkForUpdateAsync();
            if (update.isAvailable) {
              setUpdating(true);
              await Updates.fetchUpdateAsync();
              // ... notify user of update ...
              await Updates.reloadAsync();
              setUpdating(false);
            }
          } catch (e) {
            // handle or log error
          }
        }
        setAppState(nextAppState);
      };

      AppState.addEventListener('change', handleAppStateChange);

      return () => {
        AppState.removeEventListener('change', handleAppStateChange);
      };
    }
  }, [appState]);

  // This is run once
  useEffect(() => {
    (async () => {
      console.log('Starting startupAsync');
      await dispatch(startupAsync());
      console.log('Ending startupAsync');
      setInitilized(true);
    })();
  }, []);

  // render() {
  const { history } = props;
  if (!initilized)
    return (
      <View style={styles.applicationView}>
        <StatusBar barStyle="light-content" />
        <SafeAera>
          <TopNavPlaceholder />
          <LoadingScreen />
        </SafeAera>

        {updating && (
          <View
            style={{
              position: 'absolute',
              width: Metrics.screenWidth,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: Metrics.baseMargin,
            }}
          >
            <Text style={{ color: '#777' }}>
              <ActivityIndicator /> Updating App...
            </Text>
          </View>
        )}
      </View>
    );

  return (
    <View style={styles.applicationView}>
      <StatusBar barStyle="light-content" />
      <Navigation history={history} />

      {updating && (
        <View
          style={{
            position: 'absolute',
            width: Metrics.screenWidth,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: Metrics.baseMargin,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ color: '#777' }}>
            <ActivityIndicator /> Updating App...
          </Text>
        </View>
      )}
    </View>
  );
}

// const mapStateToProps = ({ app: { initilized } }) => ({
//   initilized,
// });

// // wraps dispatch to create nicer functions to call within our component
// const mapDispatchToProps = (dispatch) => ({
//   startup: () => dispatch(StartupActions.startup()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
