import { put, select, call } from 'redux-saga/effects';
import { Platform } from 'react-native';
import { push } from 'connected-react-router';
import moment from 'moment';
import { I18n, loadTranslations, setLocale } from 'react-redux-i18n';
import * as Updates from 'expo-updates';
import Url from 'url-parse';
// import Permissions from 'react-native-permissions';

import { persistCombineReducers } from 'redux-persist';
import { DEFAULT_LANGUAGE, PROGRAM_CODE } from '../Config/ApplicationConfig';

import en from '../I18n/languages/en.json';
import es from '../I18n/languages/es.json';

import AppActions from '../Redux/AppRedux';
import { isKiosk } from '../Redux/AccountRedux';
import ApplicationActions from '../Redux/ApplicationRedux';
import OptionsActions from '../Redux/OptionsRedux';
import PersistActions from '../Redux/PersistRedux';
import { getDocuments } from './DocumentSagas';
import validateToken from './AccountValidateTokenSaga';

import validateTokenAsync from './AccountValidateTokenThunk';
import { getApplicationAsync } from './ApplicationThunk';
import { getDocumentsAsync } from './DocumentThunk';

export const getPersist = (state) => state.persist;
export const getLocale = (state) => state.i18n.locale;

// process STARTUP actions
export const startupAsync = (api) => async (dispatch, getState, api) => {
  const { locale } = getState().i18n;
  const persist = getState().persist;

  const { isKiosk, token, tokenId, account } = persist;
  const { userId } = account || {};

  // console.log('locale', locale);

  // If Mobile, get Locale from Device
  // if (!locale) {
  const translationsObject = {
    en,
    es,
  };
  dispatch(loadTranslations(translationsObject));
  dispatch(setLocale(locale || DEFAULT_LANGUAGE));
  console.log('locale loaded', locale || DEFAULT_LANGUAGE);
  // }

  let loggedIn = isKiosk ? !!userId : !!(token && tokenId && userId);
  console.log('startupAsync -> loggedIn', loggedIn);
  // Check Token
  if (loggedIn && !isKiosk) {
    loggedIn = await dispatch(validateTokenAsync({ force: true }));
  }

  if (loggedIn && persist.application && persist.application.id) {
    if (!isKiosk && persist.application.id > 0) {
      // Load Application
      await dispatch(
        getApplicationAsync({
          applicationId: persist.application.id,
        }),
      );
    }
    await dispatch(getDocumentsAsync({ docType: null }));
  }

  // Done.  Tell the Application that it is initilized.
  console.log('set Initilized', Platform.OS);
  await dispatch(AppActions.AppSetInitilized(true));

  // See if there is an OTA update
  try {
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      // ... notify user of update ...
      await Updates.reloadAsync();
    }
  } catch (e) {
    // handle or log error
  }

  // Navigate to saved screen on mobile/kiosk if not home
  if (
    userId &&
    Platform.OS !== 'web' &&
    persist.screen &&
    persist.screen.pathname &&
    persist.screen.pathname !== '/'
  ) {
    console.log('INIT: navigating to ', persist.screen.pathname);
    // TODO: Set other screen attributes
    // history.replace(persist.screen.pathname);

    await dispatch(push(persist.screen.pathname));
  }

  // In the background, load options
  await dispatch(OptionsActions.OptionsLoadProgramCodes());

  if (persist?.application?.programCode) {
    await dispatch(
      OptionsActions.OptionsLoadOptions(persist.application.programCode),
    );
    await dispatch(
      OptionsActions.OptionsLoadDocTypes(persist.application.programCode),
    );
    await dispatch(
      OptionsActions.OptionsLoadScreeningQuestions(
        persist.application.programCode,
      ),
    );
  }
};

// export function* rehydrated(api) {
//   const locale = yield select(getLocale);
//   const persist = yield select(getPersist);

//   // If Mobile, get Locale from Device
//   if (!locale) {
//     const translationsObject = {
//       en,
//       es
//     };
//     yield put(loadTranslations(translationsObject));
//     yield put(setLocale('en'));
//     console.log('locale loaded');
//   }

//   // if (Platform.OS !== 'web') {
//   //   const cameraPermission = yield call(Permissions.request, 'camera');
//   //   yield put(AppActions.AppSetHasCamera(cameraPermission === 'authorized'));
//   // }

//   // Navigate to saved screen on mobile/kiosk if not home
//   if (
//     Platform.OS !== 'web' &&
//     persist.screen &&
//     persist.screen.pathname &&
//     persist.screen.pathname !== '/'
//   ) {
//     console.log('INIT: navigating to ', persist.screen.pathname);
//     // TODO: Set other screen attributes
//     // history.replace(persist.screen.pathname);
//   }

//   // Done.  Tell the Application that it is initilized.
//   console.log('set Initilized', persist);
//   yield put(AppActions.AppSetInitilized(true));
// }
